<template>
  <v-container>
    <v-navigation-drawer app dark color="primary">
      <v-list>
        <v-list-group v-for="item in menus" :key="item.title" color="white">
          <template #prependIcon>
            <v-icon class="mx-0 px-0">
              {{ item.icon }}
            </v-icon>
          </template>
          <template #activator>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </template>

          <v-list-item
            v-for="child in item.submenus"
            :key="child.name"
            :to="child.to"
            link
          >
            <v-list-item-title v-text="child.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      sidebar: true,
      menus: [
        {
          name: "Organization",
          icon: "mdi-account-group",
          submenus: [
            {
              name: "Update Details",
              icon: "mdi-account-edit",
              to: "/organization/manage/update-organization-details",
            },
          ],
        },
        {
          name: "Users",
          icon: "mdi-account-multiple",
          submenus: [
            {
              name: "Users",
              icon: "mdi-account-plus",
              to: "/organization/users/",
            },
            {
              name: "Add Users",
              icon: "mdi-account-plus",
              to: "/organization/users/add-users",
            },
            {
              name: "Add Students",
              icon: "mdi-account-plus",
              to: "/organization/manage/add-organization-users",
            },
            {
              name: "Add Student (Single)",
              icon: "mdi-account-plus",
              to: "/organization/users/single-user-entry",
            },
          ],
        },
        {
          name: "Payment",
          icon: "mdi-cash-multiple",
          submenus: [
            // {
            //   name: "Manage Fees",
            //   icon: "mdi-cash-plus",
            //   to: "/organization/manage/manage-organization-fees",
            // },
            {
              name: "Fee Heads",
              icon: "mdi-cash-plus",
              to: "/organization/payment/fee-heads",
            },
            {
              name: "Payment Summary",
              icon: "mdi-cash-refund",
              to: "/organization/payment/payment-summary",
            },
          ],
        },
        {
          name: "Room",
          icon: "mdi-cash-multiple",
          submenus: [
            {
              name: "List",
              icon: "mdi-cash-plus",
              to: "/organization/room/list",
            },
          ],
        },
        {
          name: "Notice",
          icon: "mdi-bulletin-board",
          submenus: [
            {
              name: "View List",
              icon: "mdi-bulletin-board",
              to: "/organization/notice/list/",
            },
          ],
        },
        {
          name: "Exam Board",
          icon: "mdi-message-text",
          submenus: [
            {
              name: "View List",
              icon: "mdi-bulletin-board",
              to: "/organization/examboard/list/",
            },
          ],
        },
        {
          name: "SMS",
          icon: "mdi-email",
          submenus: [
            {
              name: "Compose",
              icon: "mdi-bulletin-board",
              to: "/organization/sms/compose/",
            },
            {
              name: "Draft",
              icon: "mdi-message-text",
              to: "/organization/sms/draft/",
            },
            {
              name: "Template",
              icon: "mdi-bulletin-board",
              to: "/organization/sms/template/",
            },

            {
              name: "Schedule SMS",
              icon: "mdi-bulletin-board",
              to: "/organization/sms/schedule/",
            },
            {
              name: "Buy SMS",
              icon: "mdi-bulletin-board",
              to: "/organization/sms/buySms/",
            },
            {
              name: "SMS Report",
              icon: "mdi-bulletin-board",
              to: "/organization/sms/list/",
            },
            
          ],
        },
        {
          name: "Attendance",
          icon: "mdi-card-bulleted-off-outline",
          submenus: [
            {
              name: "Attendance Timing",
              icon: "mdi-cog-outline",
              to: "/organization/attendance/list/",
            },
            {
              name: "  Attendance Summary ",
              icon: "mdi-cog-outline",
              to: "/organization/attendance/show/",
            },
            {
              name: "  Attendance Detail ",
              icon: "mdi-cog-outline",
              to: "/organization/attendance/showDetail/",
            },
          ],
        },
        {
          name: "Meeting",
          icon: "mdi-laptop-account",
          submenus: [
            {
              name: "Generate Token",
              icon: "mdi-cog-outline",
              to: "/organization/meeting/generateToken/",
            },
            {
              name: "Teacher Meeting",
              icon: "mdi-cog-outline",
              to: "/organization/meeting/teachermeeting/",
            },
          ],
        },
        // {
        //   name: "Instructor Billing",
        //   icon: "mdi-card-bulleted-off-outline",
        //   submenus: [
        //     {
        //       name: "Billing",
        //       icon: "mdi-cog-outline",
        //       to: "/organization/billing/adminBilling/",
        //     }
           
        //   ],
        // },
        {
          name: "Org Billing",
          icon: "mdi-card-bulleted-off-outline",
          submenus: [
            {
              name: "Billing",
              icon: "mdi-cog-outline",
              to: "/organization/billing/orgBilling/",
            }
           
          ],
        },
        {
          name: "Chat Report",
          icon: "mdi-card-bulleted-off-outline",
          submenus: [
            {
              name: "Report list",
              icon: "mdi-cog-outline",
              to: "/organization/chatReport/",
            }
           
          ],
        }, 
        {
          name: "Rules",
          icon: "mdi-card-bulleted-off-outline",
          submenus: [
            {
              name: "SMS Panel Rules",
              icon: "mdi-cog-outline",
              to: "/organization/smsMail/rules/",
            }
           
          ],
        },

        {
          name: "Automation",
          icon: "mdi-card-bulleted-off-outline",
          submenus: [
            {
              name: "automation Rules",
              icon: "mdi-cog-outline",
              to: "/organization/automation/",
            }
           
          ],
        }, 
      
        // {
        //   name: "Quiz",
        //   icon: "mdi-card-bulleted-off-outline",
        //   submenus: [
        //     {
        //       name: "Create Quiz ",
        //       icon: "mdi-cog-outline",
        //       to: "/organization/quiz/",
        //     },
        //     {
        //       name: "show Quiz",
        //       icon: "mdi-cog-outline",
        //       to: "/organization/quizshow/",
        //     }
        //   ],
        // },
      ],
    };
  },
};
</script>

<style>
</style>
